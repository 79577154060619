import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styles from './DetailedMessage.module.css';
import StarRating from '../star-rating/star-rating';
import { selectOrder } from '../../services/order/reducer';
import { FROM, SENDER, TO } from '../../utils/constants';

import CommonButton from '../ui/common-button/common-button';
import { setIsPriceOfferOpen } from '../../services/take-order/reducer';
import { takeOrder } from '../../services/take-order/actions';
import ArrowBackIcon from '../../icons/ArrowBackIcon';
import LikeIcon from '../../icons/LikeIcon';
import { selectUser } from '../../services/user/reducer';
import MessageIcon from '../../icons/MessageIcon';
import Messages from '../../pages/messages/messages';
import EditIcon from '../../icons/EditIcon';
import { selectMessage } from '../../services/message/reducer';
import Chat from '../Chat/Chat';

function DetailedMessage({ messageToShow }: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  console.log(messageToShow);

  const userToShow = useSelector(selectUser);
  const userRole = userToShow?.role;

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleOnOfferPriceClick = () => {
    dispatch(setIsPriceOfferOpen(true));
  };

  // const handleOnTakeOrderClick = () => {
  //   dispatch(takeOrder(messageToShow.id));
  // };

  const handleOnMessageClick = () => {
    navigate('/messages');
  };

  const handleEditClick = () => {
    navigate('./edit-order');
  };

  return (
    <div>
      {messageToShow ? (
        <div className={styles.detailed_message}>
          <div className={styles.detailed_message__main_container}>
            <p
              className={styles.detailed_message__price}
            >{`${messageToShow.price} €`}</p>
            <button
              type="button"
              className={styles.detailed_message__button}
              onClick={handleBackClick}
            >
              <ArrowBackIcon currentColor={'var(--dark-grey)'} />
            </button>
            <p className={styles.detailed_message__link}>Full description</p>
          </div>

          <div
            className={styles.detailed_message__picture_description_container}
          >
            <div className={styles.detailed_message__picture_container}></div>
            <div className={styles.detailed_message__text_container}>
              <p className={styles.message_fromto}>From</p>
              <p className={styles.message_fromto}>To</p>
            </div>

            <div className={styles.detailed_message__routh_container}>
              <p className={styles.detailed_message__description}>
                {messageToShow.short_description}
              </p>
              <div
                className={styles.detailed_message__from_town_date_container}
              >
                <p className={styles.detailed_message__town}>
                  {messageToShow.from_town}
                </p>

                <div className={styles.detailed_message__date_container}>
                  <p className={styles.detailed_message__date}>
                    {new Date(messageToShow.from_datetime).toLocaleDateString()}
                  </p>
                  <p className={styles.detailed_message__time}>
                    {new Date(messageToShow.from_datetime).toLocaleTimeString(
                      [],
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      }
                    )}
                  </p>
                </div>
              </div>

              <div className={styles.detailed_message__to_town_date_container}>
                <p className={styles.detailed_message__town}>
                  {messageToShow.to_town}
                </p>

                <div className={styles.detailed_message__date_container}>
                  <p className={styles.detailed_message__date}>
                    {new Date(messageToShow.to_datetime).toLocaleDateString()}
                  </p>
                  <p className={styles.detailed_message__time}>
                    {new Date(messageToShow.to_datetime).toLocaleTimeString(
                      [],
                      {
                        hour: '2-digit',
                        minute: '2-digit',
                      }
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* {userRole === 'courier' ? (
            <div className={styles.detailed_message__buttons_container}>
              <CommonButton
                type={'green'}
                title={'Offer your price'}
                disabled={false}
                onClick={handleOnOfferPriceClick}
              />
            </div>
          ) : (
            <CommonButton
              type={'blue'}
              title={'New delivery price offers'}
              disabled={false}
              onClick={handleOnMessageClick}
              icon={<MessageIcon currentColor={'var(--white)'} />}
            />
          )} */}
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default DetailedMessage;
