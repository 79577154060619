const EditIcon = ({ currentColor }) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 21.5H3"
        stroke={currentColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.88 7.49998L11 16.33L7 17.5L8.17 13.5L17.05 4.61998C17.4256 4.21343 17.9471 3.97247 18.5002 3.94997C19.0532 3.92747 19.5926 4.12528 20 4.49998C20.1932 4.70272 20.3437 4.94223 20.4426 5.20427C20.5414 5.46632 20.5866 5.74556 20.5754 6.0254C20.5642 6.30524 20.4968 6.57997 20.3774 6.83329C20.2579 7.0866 20.0888 7.31332 19.88 7.49998Z"
        stroke={currentColor}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
