import styles from './PriceOffer.module.css';
import { Button, Form, Input, Select, Space } from 'antd';
import SubmitButton from '../ui/submit-button/submit-button';
import CommonButton from '../ui/common-button/common-button';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectTakeOrderAttempts,
  setIsPriceOfferOpen,
} from '../../services/take-order/reducer';
import { takeNewOrder } from '../../utils/api';
import { offerPrice } from '../../services/take-order/actions';
import { selectOrder } from '../../services/order/reducer';
import { useLocation } from 'react-router-dom';
import { selectMessage } from '../../services/message/reducer';

function PriceOffer() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [form] = Form.useForm();

  console.log(location);

  const pathArray = location.pathname.split('/').filter(Boolean);

  const orderToShow = useSelector(selectOrder);
  const messageToShow = useSelector(selectMessage);

  const currentId =
    (pathArray.includes('messages') && messageToShow?.id_order) ||
    (pathArray.includes('orders') && orderToShow?.id);

  const attempts = useSelector(selectTakeOrderAttempts);

  function handleOnPriceOfferClose() {
    dispatch(setIsPriceOfferOpen(false));
  }

  function handleFormSubmit(e) {
    dispatch(offerPrice({ e, currentId }));
    dispatch(setIsPriceOfferOpen(false));
  }

  return (
    <div className={styles.price_offer}>
      <p className={styles.price_offer__title}>
        {`You have ${attempts} attempts left to negotiate about delivery price.`}
      </p>
      <p className={styles.price_offer__note}>
        How much do you want to get paid for this delivery?
      </p>

      <Form form={form} name="price-offer" onFinish={handleFormSubmit}>
        <Form.Item
          name="price"
          rules={[
            {
              required: true,
              message: 'Use only numbers',
            },
          ]}
        >
          <Input
            size="large"
            placeholder="Your price"
            type="number"
            prefix="€"
            suffix="EUR"
          />
        </Form.Item>

        <div className={styles.price_offer__button_container}>
          <CommonButton
            form={form}
            type={'blue'}
            title={'Cancel'}
            onClick={handleOnPriceOfferClose}
          ></CommonButton>

          <Form.Item style={{ width: '100%' }}>
            <SubmitButton
              form={form}
              type={'green'}
              title={'Offer your price'}
            ></SubmitButton>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}

export default PriceOffer;
