import { Button, ConfigProvider } from 'antd';
import MessageIcon from '../../../icons/MessageIcon';
import { PoweroffOutlined, SyncOutlined } from '@ant-design/icons';

function CommonButton({ type, title, icon = null, disabled, onClick }) {
  const blueButton = {
    components: {
      Button: {
        contentFontSizeLG: 'var(--font-size-16)',
        borderColorDisabled: 'var(--dark-grey)',
        defaultBg: 'var(--blue)',
        defaultBorderColor: 'var(--blue)',
        defaultColor: 'var(--white)',
        defaultHoverBg: 'var(--blue-hover)',
        defaultHoverBorderColor: 'var(--blue)',
        defaultHoverColor: 'var(--light-green)',
        defaultShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
        fontWeight: 'var(--font-weight-medium)',
      },
    },
  };

  const greenButton = {
    components: {
      Button: {
        contentFontSizeLG: 'var(--font-size-16)',
        borderColorDisabled: 'var(--dark-grey)',
        defaultBg: 'var(--light-green)',
        defaultBorderColor: 'var(--light-green)',
        defaultColor: 'var(--blue)',
        defaultHoverBg: 'var(--light-green-hover)',
        defaultHoverBorderColor: 'var(--light-green)',
        defaultHoverColor: 'var(--blue)',
        defaultShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
        fontWeight: 'var(--font-weight-medium)',
      },
    },
  };

  const whiteButton = {
    components: {
      Button: {
        contentFontSizeLG: 'var(--font-size-16)',
        borderColorDisabled: 'var(--dark-grey)',
        defaultBg: 'var(--white)',
        defaultBorderColor: 'var(--ligyt-blue)',
        defaultColor: 'var(--blue)',
        defaultHoverBg: 'rgba(0, 0, 255, 0.1)',
        defaultHoverBorderColor: 'var(--blue)',
        defaultHoverColor: 'var(--blue)',
        defaultShadow: '0px 3px 5px 0px rgba(0, 0, 0, 0.2)',
        fontWeight: 'var(--font-weight-medium)',
      },
    },
  };

  const buttonColor =
    type === 'blue' ? blueButton : type === 'green' ? greenButton : whiteButton;

  return (
    <ConfigProvider theme={buttonColor}>
      <div style={{ width: '100%' }}>
        <Button
          size="large"
          icon={icon || undefined}
          disabled={disabled}
          onClick={onClick}
          block
        >
          {title}
        </Button>
      </div>
    </ConfigProvider>
  );
}

export default CommonButton;
