import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../utils/api';

export const takeOrder = createAsyncThunk('order/takeOrder', async (id) => {
  return api.takeNewOrder(id);
});

export const declineOrder = createAsyncThunk(
  'order/declineOrder',
  async (id) => {
    return api.declineNewOrder(id);
  }
);

export const offerPrice = createAsyncThunk(
  'order/offerPrice',
  async ({ e, currentId }) => {
    return api.offerNewPrice(e, currentId);
  }
);
