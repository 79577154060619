import { createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../utils/api';

import { setUser } from './reducer';

export const getUser = createAsyncThunk('user/getUser', async () => {
  return api.getUserInfo();
});

export const registerUser = createAsyncThunk('user/registerUser', async (e) => {
  return api.signup(e);
});

export const loginUser = createAsyncThunk('user/loginUser', async (e) => {
  return api.login(e);
});

export const logoutUser = createAsyncThunk('user/logoutUser', async () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  setUser(null);
});

export const receiveUserOrders = createAsyncThunk(
  'user/receiveUserOrders',
  async (e) => {
    return api.getUserOrdersInfo(e);
  }
);

export const receiveUserMessages = createAsyncThunk(
  'user/receiveUserMessages',
  async (e) => {
    return api.getUserMessagesInfo(e);
  }
);
