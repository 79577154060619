import { useDispatch, useSelector } from 'react-redux';
import styles from './detailed-message.module.css';
import Chat from '../../components/Chat/Chat';
import DetailedMessage from '../../components/DetailedMessage/DetailedMessage';
import { selectMessage } from '../../services/message/reducer';
import CommonButton from '../../components/ui/common-button/common-button';
import { selectUser } from '../../services/user/reducer';
import { useNavigate } from 'react-router-dom';
import { setIsPriceOfferOpen } from '../../services/take-order/reducer';
import { declineOrder, takeOrder } from '../../services/take-order/actions';
import CommonInput from '../../components/ui/common-input/common-input';
import CustomInput from '../../components/ui/CustomInput/CustomInput';
import { Input } from 'antd';

function DetailedMessagePage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const messageToShow = useSelector(selectMessage);
  const isChatInput = messageToShow?.order_status.taken;
  const chatMessages = messageToShow?.messages.map((item) => ({
    text: item.content,
    time: item.date,
    role: item.role,
  }));

  const userToShow = useSelector(selectUser);
  const userRole = userToShow?.role;

  const handleOnOfferPriceClick = () => {
    dispatch(setIsPriceOfferOpen(true));
  };

  const handleOnTakeOrderClick = () => {
    dispatch(takeOrder(messageToShow.id_order));
  };

  const handleOnDeclineClick = () => {
    dispatch(declineOrder(messageToShow.id_order));
  };

  return (
    <section className={styles.section}>
      {messageToShow && (
        <div className={styles.container}>
          <DetailedMessage messageToShow={messageToShow} />
          <div className={styles.text_container}>
            <p className={styles.text_black}>{`${
              messageToShow.role === 'sender' ? 'Sender' : 'Courier'
            }`}</p>
            <p
              className={styles.text_blue}
            >{`${messageToShow.first_name} ${messageToShow.last_name}`}</p>
          </div>
          <Chat messages={chatMessages} />
          {userRole === 'courier' && !isChatInput && (
            <div className={styles.buttons_box}>
              <div className={styles.buttons}>
                <CommonButton
                  type={'green'}
                  title={'Offer your price'}
                  disabled={false}
                  onClick={handleOnOfferPriceClick}
                />
                <CommonButton
                  type={'blue'}
                  title={'Take order'}
                  disabled={false}
                  onClick={handleOnTakeOrderClick}
                />
              </div>
            </div>
          )}
          {userRole === 'sender' && !isChatInput && (
            <div className={styles.buttons_box}>
              <CommonButton
                title={'Offer your price'}
                disabled={false}
                onClick={handleOnOfferPriceClick}
              />
              <div className={styles.buttons}>
                <CommonButton
                  type={'blue'}
                  title={'Decline'}
                  disabled={false}
                  onClick={handleOnDeclineClick}
                />
                <CommonButton
                  type={'green'}
                  title={'Confirm'}
                  disabled={false}
                  onClick={handleOnTakeOrderClick}
                />
              </div>
            </div>
          )}
          {isChatInput && (
            <div className={styles.button_input_box}>
              <Input placeholder={`Enter your message`} />
              <div>
                <CommonButton
                  type={'blue'}
                  title={'Send'}
                  disabled={false}
                  onClick={handleOnDeclineClick}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </section>
  );
}

export default DetailedMessagePage;
