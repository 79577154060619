import { createSlice } from '@reduxjs/toolkit';
import { loadMessage } from './actions';

const initialState = {
  message: null,
  error: null,
  loading: false,
};

export const messageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    deleteMessageValue: (state) => {
      state.message = null;
    },
    setMessageError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loadMessage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadMessage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(loadMessage.fulfilled, (state, action) => {
        state.loading = false;
        state.message = action.payload;
      });
  },
});

export const { deleteMessageValue, setMessageError } = messageSlice.actions;

export const selectMessage = (state) => state.message.message;
export const selectMessageLoading = (state) => state.message.loading;
export const selectMessageError = (state) => state.message.error;

export const reducer = messageSlice.reducer;
export default reducer;
