import ChatMessage from '../ChatMessage/ChatMessage';
import CommonButton from '../ui/common-button/common-button';
import styles from './Chat.module.css';

type Props = {
  text: string;
  time: string;
  role: string;
};

type ChatProps = {
  messages: Props[];
};

function Chat({ messages }: ChatProps) {
  return (
    <div className={styles.chat_container}>
      <div className={styles.container}>
        {messages.map((item, index) => (
          <ChatMessage
            text={item.text}
            time={item.time}
            role={item.role}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default Chat;
