import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import styles from './messages.module.css';
import TopMenu from '../../components/top-menu/top-menu';
import MessageCard from '../../components/MessageCard/MessageCard';
import { selectUser, selectUserMessages } from '../../services/user/reducer';
import { receiveUserMessages } from '../../services/user/actions';

function Messages() {
  const dispatch = useDispatch();

  const messagesToShow = useSelector(selectUserMessages);
  const user = useSelector(selectUser);
  const userRole = user.role;

  useEffect(() => {
    if (userRole === 'courier') {
      dispatch(receiveUserMessages('courier'));
    } else if (userRole === 'sender') {
      dispatch(receiveUserMessages('sender'));
    }
  }, [dispatch]);

  return (
    <div className={styles.current_messages}>
      <TopMenu />
      <div className={styles.current_messages__container}>
        {messagesToShow
          ?.slice()
          .reverse()
          .map((item) => (
            <MessageCard item={item} key={uuidv4()} />
          ))}
      </div>
    </div>
  );
}

export default Messages;
