import { useSelector } from 'react-redux';
import { selectUser } from '../../services/user/reducer';
import styles from './ChatMessage.module.css';

type ChatMessageProps = {
  text: string;
  time: string;
  role: string;
};

function ChatMessage({ text, time, role }: ChatMessageProps) {
  const userToShow = useSelector(selectUser);
  const userRole = userToShow?.role;

  return (
    <div
      className={`${styles.container} ${
        userRole === role ? styles.right : styles.left
      }`}
    >
      <p>{text}</p>
      <div className={styles.detailed_message__date_container}>
        <p className={styles.detailed_message__time}>
          {new Date(time).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          })}
        </p>
      </div>
    </div>
  );
}

export default ChatMessage;
